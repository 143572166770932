<template>
  <v-dialog v-model="dialogStatus" persistent max-width="500px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon
                  >{{ $vuetify.lang.t("$vuetify.modifyStatus") }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0" cols="12">
                <v-select 
                  :items="orderStatus" 
                  :label="$vuetify.lang.t('$vuetify.status')"
                  v-model="selectedStatus"
                  :rules="generalRule"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="blue darken-2" @click="validate">{{
          $vuetify.lang.t("$vuetify.confirm")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>

<script>

export default {
  name: "modifyStatus",
  props: { dialogStatus: Boolean },
  methods: {
    cancelForm() {
      this.$emit("update:dialogStatus", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    validate(){
      this.$refs.form.validate() == true ? this.$emit("getStatusData", this.selectedStatus) : console.log("false");
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  data() {
    return {
      valid: false,
      selectedStatus: '',
      generalRule: [(v) => !!v || "this field is required"],
      orderStatus: [
        {
          text: this.$vuetify.lang.t("$vuetify.cancel"),
          value: "-1"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.placeOrder"),
          value: "1"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.paid"),
          value: "3"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.shipped"),
          value: "6"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.offStocks"),
          value: "7"
        }
      ],
    }
  },
};
</script>